import React from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings, getCorrectColor } from 'libs/content';
import { ComponentWrapper, ImageGradient } from 'components/ComponentWrapper';
import Article from 'components/Article';
import { ShadowBoxStyle } from 'components/forms/Box';

const Inner = styled.div`
	.component__article {
		display: flex;
		flex-direction: ${p => (p.align === 'right' ? 'row-reverse' : 'row')};
		align-items: center;
		${p =>
			p.theme.media.smallOnly(css`
				flex-direction: column;
				> div {
					width: 100%;
					text-align: center;
				}
			`)}
	}

	// Media content
	.component__article--media {
		flex: 0 0 50%;
		text-align: inherit;
	}

	// Text content
	.component__article--text {
		flex: 0 0 50%;
	}

	// Halfwidth layout
	${p =>
		p.layout === 'halfwidth' &&
		css`
			${p =>
				p.theme.media.medium(css`
					.component__article--media .component-wrapper__width-limit {
						padding: 0;
					}
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					.component__article--media {
						padding-bottom: 30px;
					}
				`)}
		`}

	// One third layout
	${p =>
		p.layout === 'onethird' &&
		p.theme.media.medium(css`
			.component__article--media {
				flex: 1 1 25%;
				text-align: center;
			}
			.component__article--text {
				flex: 1 1 75%;
			}
		`)}

	// Left alignment
	${p =>
		p.align === 'left' &&
		p.theme.media.medium(css`
			.component__article--media {
				padding: 0 20px 0 0;
			}
			.component__article--text {
				padding: 0 0 0 20px;
			}
		`)}

	// Right alignment
	${p =>
		p.align === 'right' &&
		p.theme.media.medium(css`
			.component__article--media {
				padding: 0 0 0 20px;
			}
			.component__article--text {
				padding: 0 20px 0 0;
			}
		`)}

	${p =>
		p.layout === 'fullwidth' &&
		css`
			width: 100%;
			.component__article--text {
				flex: 1 1 100%;
			}
		`}

	&.shadow-box {
		${ShadowBoxStyle}
		padding: 40px;
		${p =>
			p.theme.media.smallOnly(css`
				padding: 30px;
			`)}
	}
`;

const StyledComponentWrapper = styled(ComponentWrapper)`
	.attention-section {
		max-width: 1320px;
		margin: 0 auto;
		padding: 30px 20px !important;

		&__width-limit {
			padding: 0;
		}

		.component__article {
			justify-content: space-between;
			gap: 20px;
		}
	}

	@media (min-width: 1320px) {
		.attention-section {
			border-radius: ${p => p.theme.utils.borderRadius};
		}
	}

	@media (min-width: 768px) {
		.attention-section {
			.component__article--text {
				flex: 0 1 692px;
				padding: 0;
			}
			.component__article--media {
				flex: 0 1 390px;
				padding: 0;
			}
		}
	}

	@media (min-width: 1100px) {
		.attention-section {
			padding: 60px !important;
		}
	}
`;

/**
 * Represents an image and content part
 * @param {React.ReactNode} component - Component to pass as content instead of text
 * @param {...object} props - The parameters for the component
 */
export default function ImageAndContent({ component, ...props }) {
	const settings = {
		...getComponentSettings({ settings: props?.settings, props }),
		...getSpecificProps(props),
	};

	let wrapperClass = '';

	// If previous component is a topbanner and has setting to float over, then add class
	if (
		props?.prevcomponent?.__typename === 'ContentfulKomponentToppbanner' &&
		props?.prevcomponent?.settings?.includes(
			'Neste komponent skal flyte over'
		)
	) {
		wrapperClass += 'shadow-box';
	}

	const componentClass = `component__image-and-content ${
		settings.bg &&
		settings.bg !== 'transparent' &&
		settings.maxwidth === 'true'
			? 'attention-section'
			: ''
	}`;

	return (
		<StyledComponentWrapper
			className={componentClass}
			spacing={props?.spacing}
			componentindex={props?.componentindex}
			width={settings?.align === 'center' ? 'medium' : 'default'}
			{...settings}>
			<Inner
				layout={settings?.layout}
				align={settings?.align}
				className={wrapperClass}>
				<Article
					as="div"
					component={component}
					{...props}
					{...settings}
				/>
				{settings?.gradient === 'true' &&
					settings?.layout === 'fullwidth' && (
						<ImageGradient {...settings} />
					)}
			</Inner>
		</StyledComponentWrapper>
	);
}

/**
 * Get specific props for the component
 * @param {object} props - The props for the component
 * @returns {object} The specific props for the component
 */
function getSpecificProps({ bgColor, imageposition, image, backgroundImage }) {
	// Get background color
	const bg =
		(bgColor === 'Transparent' && imageposition?.includes('Fullbredde')) ||
		(!bgColor && imageposition?.includes('Fullbredde'))
			? 'grey900'
			: bgColor
			? getCorrectColor(bgColor)
			: 'transparent';

	// Get layout variant
	const layout = imageposition?.includes('Fullbredde')
		? 'fullwidth'
		: imageposition?.includes('1/3')
		? 'onethird'
		: 'halfwidth';

	// Get alignment
	const align = imageposition?.includes('høyre')
		? 'right'
		: imageposition?.includes('venstre')
		? 'left'
		: 'center';

	// Get image height
	const isSvgImage = image?.height < 768 && image?.svg?.originalContent;
	const imageheight = isSvgImage ? image?.height : 300;

	// Get background image
	let bgimage = null;
	if (imageposition?.includes('Fullbredde')) {
		bgimage = image?.file?.url || backgroundImage?.file?.url;
	} else if (backgroundImage?.file?.url) {
		bgimage = backgroundImage?.file?.url;
	}

	return {
		layout,
		align,
		bg,
		bgimage,
		gradient: layout === 'fullwidth' && !!bgimage ? 'true' : 'false',
		imageheight,
		linkelement: 'button',
	};
}
